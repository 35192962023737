<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>CIERRE</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'DESISTIMIENTO'" :descripcion="registro.gescierre.desistimiento.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'PRÓRROGA'" :descripcion="registro.gescierre.prorroga.descripcion"></texto2>
          </v-col>
        </v-row>
        <br>
        <b>RESUMEN RESPUESTA</b>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card-text class="pa-0" style="white-space:pre-wrap;">
                <div >{{ registro.gescierre.resumen }}</div>
            </v-card-text>
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <b>ARCHIVOS DE RESPUESTA FINAL</b>
          </v-col>
          <v-col cols="12" md="6" >
            <b>ARCHIVOS DE ANEXOS</b>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <div v-for="(itemFile, i) in registro.gescierrefiles" :key="i">
              <verArchivos v-if="itemFile.tipo_id===1 && itemFile.replica_id === null" :colores="colores" :tipo="'CIERREFINAL'" :item="itemFile"></verArchivos>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div v-for="(itemFile, i) in registro.gescierrefiles" :key="i">
              <verArchivos v-if="itemFile.tipo_id===2 && itemFile.replica_id === null" :colores="colores" :tipo="'CIERREFINAL'" :item="itemFile"></verArchivos>
            </div>
          </v-col>
        </v-row>
        <br>
        <v-divider></v-divider>
        <br>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'FAVORABILIDAD'" :descripcion="registro.gescierre.favorabilidad.descripcion"></texto2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="registro.gesconsumidor">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'ACEPTACIÓN'" :descripcion="(registro.gesconsumidor.aceptacion)?registro.gesconsumidor.aceptacion.descripcion:''"></texto2>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'RECTIFICACIÓN'" :descripcion="(registro.gesconsumidor.rectificacion)?registro.gesconsumidor.rectificacion.descripcion:''"></texto2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="registro.gestutela">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'RESULTADO FALLO TUTELA'" :descripcion="registro.gestutela.resultado.descripcion"></texto2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>ANÁLISIS REQUERIMIENTO</b></span></v-toolbar>
      <v-card-text>
        <template v-for="(item, index) in registro.gesanalisis">
          <div :key="index">
            <v-row no-gutters>
              <v-col cols="12" md="1" align-self="center"> <v-avatar :color="colores.primario" class="white--text"> {{ index + 1 }} </v-avatar></v-col>
              <v-col cols="12" md="11">
                    <v-row no-gutters>
                      <v-col cols="12" md="6" class="pr-1">
                        <texto2 :colores="colores" :titulo="'CAUSAL'" :descripcion="item.causal.descripcion"></texto2>
                      </v-col>
                      <v-col cols="12" md="6">
                        <texto2 :colores="colores" :titulo="'MOTIVO'" :descripcion="item.motivo.descripcion"></texto2>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <texto2 :colores="colores" :titulo="'SUBMOTIVO'" :descripcion="item.submotivo.descripcion"></texto2>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" md="4" class="pr-1">
                        <texto2 v-if="item.responsablequeja" :colores="colores" :titulo="'RESPONSABLE QUEJA'" :descripcion="item.responsablequeja.descripcion"></texto2>
                      </v-col>
                      <v-col cols="12" md="4" class="pr-1">
                        <texto2 v-if="item.causalqueja" :colores="colores" :titulo="'CAUSA QUEJA'" :descripcion="item.causalqueja.descripcion"></texto2>
                      </v-col>
                      <v-col cols="12" md="4">
                        <texto2 v-if="item.marcacion" :colores="colores" :titulo="'MARCACIÓN SEGUIMIENTO'" :descripcion="item.marcacion.descripcion"></texto2>
                      </v-col>
                    </v-row>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
// import texto2 from '@/components/widgets/text2Component.vue'
export default {
  name: 'crm_lectura_cierreComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue'),
    verArchivos: () => import('@/components/widgets/ver_archivosComponent.vue')
  },
  props: ['colores', 'registro'],
  data: () => ({
  }),
  created () {

  },
  methods: {

  }

}
</script>
